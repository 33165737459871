// collapsible component
const collapsibles = document.querySelectorAll('.collapsible');
collapsibles.forEach(item => {
  item.addEventListener('click', function () {
    this.classList.toggle('collapsible--expanded');
  });
});

// initialize animation
AOS.init({ once: true });

const languageKey = 'SMLanguage';

function changeLanguage(languageCode) {
  localStorage.setItem(languageKey, languageCode);

  Array.from(document.getElementsByClassName('lang')).forEach(function (
    elem
  ) {
    if (elem.classList.contains('lang-' + languageCode)) {
      elem.style.display = 'initial';
    } else {
      elem.style.display = 'none';
    }
  });

  window.scrollTo({ top: 0, behavior: 'smooth' });
}

document
  .getElementById('fil-lang')
  .addEventListener('click', function (event) {
    event.stopPropagation();
    changeLanguage('fil');
  });

document
  .getElementById('usa-lang')
  .addEventListener('click', function (event) {
    event.stopPropagation();
    changeLanguage('en');
  });

const selectedLanguage = localStorage.getItem(languageKey)
  ? localStorage.getItem(languageKey)
  : 'en';

document.addEventListener('DOMContentLoaded', event => {
  // set language
  changeLanguage(selectedLanguage);
});
